//Libs
import React from 'react';
// Components
import Guide from 'components/promptService/recipeGuide';
// Style
import { Container, LeftSide, RightSide, ContentMarkdown } from './style';
const RecipeGuide = ({
  image,
  markdownContent,
  children,
  diretrizesParaRetomada,
  alertText,
  buttonLabel,
  filesUrlToDownload,
  pageReference,
}) => {
  return (
    <Container>
      <LeftSide diretrizesParaRetomada={diretrizesParaRetomada}>
        {image?.handle && (
          <img
            src={`${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${image.handle}`}
            alt="Guia de Receitas"
          />
        )}
      </LeftSide>
      <RightSide>
        <ContentMarkdown children={markdownContent} />
        {children ? (
          children
        ) : (
          <Guide
            alertText={alertText}
            buttonLabel={buttonLabel}
            filesUrlToDownload={filesUrlToDownload}
            pageReference={pageReference}
          />
        )}
      </RightSide>
    </Container>
  );
};
export default RecipeGuide;
