// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// Components
import { device } from 'components/device';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import ListPosts from 'components/blog/listPosts';
import Qa from 'components/Qa';
import RecipeGuide from 'components/RecipeGuide';
import Components from 'components/CmsComponents/';
import ServicesDefault from 'components/units/unitPages/unit/servicesDefault';

// Context
import { HeaderContext } from 'context/header';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  padding-bottom: ${props => props.paddingBottom && '70px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};

  ${props =>
    props.bgColor &&
    css`
      background: ${props.bgColor};
    `}

  ${props =>
    props.paddingDesktop &&
    css`
      padding: ${props.paddingDesktop};
    `}

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin: ${props => props.isMarginFullWidth && '0 -1.25rem'};
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }

    ${props =>
      props.fullWidthMobile &&
      css`
        width: 100vw;
        margin: 0 -1.25rem;
      `}

    ${props =>
      props.paddingMobile &&
      css`
        padding: ${props.paddingMobile};
      `}

    ${props =>
      props.marginTopMobile &&
      css`
        margin-top: ${props.marginTopMobile};
      `}
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const RenderPage = (page, isDesktop, pageContext) => {
  const { doctorsDataCMS } = pageContext;

  const featuredMarketingContent = page[0]?.featuredMarketing;
  const components = page[0]?.components ?? [];

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part gridColumn={'2 / -2'} notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckvwlfvu0hh650c295dhjw8ie', true)}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            withoutBorderTop
            isPatologica
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} Bottom={'2.5rem'} isNotBorder borderTop>
          <ServicesDefault
            markdown={getMarkdown(page, 'ckzfmoitcykqc0a275an76i2i', true)}
            fragments={getMarkdown(page, 'ckzfmoitcykqc0a275an76i2i')}
            isCDIUnits
            isOncologia
            isCentroOncologico
            // isAdjustmentInCssLayout
            isAdjustmentInCssLayoutMobile
            isCarousel
            heightP="6.5rem"
          />
        </Part>
        <Part gridColumn={'1 / -1'} isMarginFullWidth>
          {components.length >= 1 &&
            components?.map(component => {
              return Components(component);
            })}
        </Part>
        <Part
          gridColumn={'1 / -1'}
          fullWidthMobile
          paddingDesktop="4.375rem 0"
          paddingMobile="1.25rem 0"
          marginTopMobile="1.25rem"
          bgColor="linear-gradient(110.39deg, #92559B 0%, #57539F 100%);"
        >
          <RecipeGuide
            markdownContent={getMarkdown(
              page,
              'ckvwqvt2okca10a26x8yug92j',
              true
            )}
            image={getMarkdown(page, 'ckvwqvt2okca10a26x8yug92j', false, true)}
            alertText={
              'Ao clicar em "fazer download gratuito", você baixa o guia de receitas e permite que o Sírio-Libanês armazene o seu e-mail para fornecer o conteúdo solicitado. Você pode cancelar o recebimento quando quiser.'
            }
            buttonLabel="Fazer download gratuito"
            filesUrlToDownload={[
              `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/KvOFVeibTJG0jdKffuYu`,
            ]}
            pageReference="Cardiologia"
          />
        </Part>
        {page?.[0]?.qa && (
          <Part gridColumn={'2 / -2'}>
            <Qa title={'Perguntas Frequentes'} data={page?.[0]?.qa} />
          </Part>
        )}
        {page?.[0]?.posts?.length > 0 && (
          <Part gridColumn={'2 / -2'} padding>
            <ListPosts
              title="Blog do Sírio-Libanês"
              postsToShow={3}
              posts={page?.[0]?.posts}
              doctorsData={doctorsDataCMS}
              headerLink={`/${page[0]?.customURLBlog ?? 'blog/'}`}
            />
          </Part>
        )}
      </GridContainer>
    </>
  );
};

const CardiologyCenter = ({ page, pageContext }) => {
  const [isDesktop, setDesktop] = useState(true);
  const [, setRedirect] = useState(false);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      setDesktop(window.innerWidth > 1024);
      return () => window.removeEventListener('resize', updateMedia);
    }
    setRedirect(true);
  });

  const getImage = getMarkdown(page, 'ckvwle7nchizn0a26vz200url', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {RenderPage(page, isDesktop, pageContext)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckvwl482whcus0a26o42i0ttc" }) {
                featuredMarketing(locales: [pt_BR, en, es]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeHighlight
                  highlight
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_SimpleContent {
                    id
                    name
                    type
                    title
                    fragments {
                      id
                      name
                      type
                      markdown
                      datafragment
                      singletexts
                    }
                  }
                  ... on GraphCMS_ProfessioanlSpeech {
                    id
                    title
                    professionalName
                    professional
                    text
                    image {
                      handle
                      width
                      height
                      url
                    }
                  }
                  ... on GraphCMS_DoctorAndSpecialtyContent {
                    id
                    name
                    type
                    title
                    subtitle
                    estado
                    images {
                      handle
                      width
                      height
                      url
                    }
                    specialtiesCardios {
                      id
                      name
                      slug
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                qa(locales: [pt_BR, en]) {
                  titulo
                  pergunta
                  autor
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <CardiologyCenter page={response.gcms.site.pages} {...props} />;
      }}
    />
  );
};
