import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

import { device } from 'components/device';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.875rem;
  width: 100%;
  height: 100%;
  max-width: 1170px;
  margin: 0 auto;

  @media ${device.laptop} {
    flex-direction: column;
    gap: 1.25rem;
    padding: 0 1.25rem;
  }
`;

export const LeftSide = styled.div`
  width: 100%;
  height: 100%;
  min-height: 52rem;
  max-width: 29.375rem;

  img {
    width: 100%;
    min-height: 100%;

    object-fit: cover;
    object-position: center;
  }

  @media ${device.laptop} {
    max-width: unset;
    height: 13.375rem;
    margin-top: ${props => props.diretrizesParaRetomada ? '-4rem' : '-2.5rem'};
    min-height: 100%;

    img {
      width: 100%;
      height: 13.375rem;

      object-fit: cover;
      object-position: center 40%;
    }
  }

  @media ${device.mobile} {
    img {
      object-position: center;
    }
  }
`;

export const RightSide = styled.div`
  width: 100%;
  max-width: 35.625rem;

  @media ${device.laptop} {
    max-width: unset;
  }
`;

export const ContentMarkdown = styled(ReactMarkdown)`
  margin-bottom: 3.125rem;

  @media ${device.laptop} {
    margin-bottom: 1.25rem;
  }

  h2 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    color: #ffffff;
    font-weight: normal;
    margin-bottom: 3.125rem;

    strong {
      display: block;
    }

    @media ${device.tablet} {
      font-size: 1.125rem;
      line-height: 1.875rem;
      margin-bottom: 1.25rem;
    }

    @media ${device.mobile360} {
      strong {
        display: inline;
      }
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #ffffff;

    & + p {
      margin-top: 1.5rem;
    }

    strong {
      font-weight: 600;
    }

    @media ${device.tablet} {
      font-size: 0.875rem;
      line-height: 1.625rem;

      & + p {
        margin-top: 1.25rem;
      }
    }
  }
`;
